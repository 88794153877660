<template>
   <div class="container">
      <div class="row">
         <div class="col-12">
            <div class="sign__content">
               <!-- authorization form -->
               <form v-on:submit.prevent="onLogin()" ref="formContainer" class="sign__form vld-parent">
                  <a href="index.php" class="sign__logo">
                     <img src="assets/img/logoSvg.svg" alt="">
                  </a>
                  <div class="sign__group">
                     <span class="sign__delimiter">رقم الهاتف </span>
                     <input type="phone" v-model="phone" class="sign__input" pattern="^\+?(?:[0-9]??).{9,13}[0-9]$"
                        placeholder="09XXXXXXXX">
                  </div>
                  <div class="sign__group">
                     <button class="sign__btn" type="submit">تسجيل دخول</button>
                     <span class="sign__delimiter">
                        <h6></h6>
                     </span>
                  </div>
               </form>
               <!-- end authorization form -->
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import config from '@/Api/config';

export default {
   name: 'Logins',
   data() {
      return {
         phone: "",
      };
   },
   methods: {
      onLogin() {
         let loader = this.$loading.show({
            // Optional parameters
            container: this.oldNum ? null : this.$refs.formContainer,
            // canCancel: true,
            // onCancel: this.onCancel,
         });
         // simulate AJAX
         setTimeout(() => {
            loader.hide();
         }, 6000);
         const toast = useToast();


         // ////////////////////////////////////////////////////////
         if (this.phone != "") {

            // Remove non-numeric characters
            let phoneNumber = this.phone.replace(/\D/g, '');
            // Define the regex pattern for Sudan Zain numbers
            const zainRegex = /^(?:\+2499|002499|2499|09|9)(0|1|6)\d{7}$/;

            // Test the input against the regex
            if (zainRegex.test(phoneNumber)) {
               // If valid, store and show the formatted number
               // this.formattedNumber = phoneNumber;
               // this.phoneNumberError = false;

               let pNumber = '';
               if (phoneNumber.substring(0, 1) === "0" && phoneNumber.length === 10) {
                  pNumber = phoneNumber.slice(1);

               } else if (phoneNumber.substring(0, 1) === "9" && phoneNumber.length === 9) {
                  pNumber = phoneNumber.slice();

               } else if (phoneNumber.substring(0, 1) === "2" && phoneNumber.length === 12) {
                  pNumber = phoneNumber.slice(3);

               } else if (phoneNumber.substring(0, 1) === "+" && phoneNumber.length === 13) {
                  pNumber = phoneNumber.slice(4);

               } else if (phoneNumber.substring(0, 1) === "0" && phoneNumber.length === 14) {
                  pNumber = phoneNumber.slice(5);
                  // console.log(pNumber)
               }

               axios.get(
                  "CheckLoginByProduct_Code.php?msisdn=249" + pNumber + "&product_code=" + this.$cookie.getCookie("product_code")
                  , {
                     headers: {
                        'Authorization': config.authHeader,
                     },
                  }).then((response) => {
                     if (response.data.status == 1) {
                        this.$cookie.setCookie('status', response.data.status, {
                           expire: 60 * response.data.remming_minutes,
                        })
                        this.$cookie.setCookie('mdn', response.data.mdn, {
                           expire: 60 * response.data.remming_minutes,
                        })
                        this.$cookie.setCookie('minutes', response.data.remming_minutes, {
                           expire: 60 * response.data.remming_minutes,
                        })
                        toast.success("تم تسجيل دخولك استمتع بتجربة فريدة", {
                           timeout: 4000
                        });
                        setTimeout(() =>
                           this.$router.push({
                              path: "/"
                           }),
                           4000
                        );
                     } else if (response.data.status == 0 && response.data.remming_minutes > 0) {

                        this.$cookie.setCookie('status', response.data.status, {
                           expire: 60 * response.data.remming_minutes,
                        })
                        this.$cookie.setCookie('mdn', response.data.mdn, {
                           expire: 60 * response.data.remming_minutes,
                        })
                        this.$cookie.setCookie('minutes', response.data.remming_minutes, {
                           expire: 60 * response.data.remming_minutes,
                        })
                        toast.success("تم تسجيل دخولك استمتع بتجربة فريدة", {
                           timeout: 4000
                        });
                        setTimeout(() =>
                           this.$router.push({
                              path: "/"
                           }),
                           4000
                        );
                     } else if (response.data.status == 1 && response.data.remming_minutes < 0) {

                        this.$cookie.setCookie('status', response.data.status, {
                           expire: 60 * 120,
                        })
                        this.$cookie.setCookie('mdn', response.data.mdn, {
                           expire: 60 * 120,
                        })
                        this.$cookie.setCookie('minutes', response.data.MINTUES, {
                           expire: 60 * 120,
                        })
                        toast.success("تم تسجيل دخولك استمتع بتجربة فريدة", {
                           timeout: 4000
                        });
                        setTimeout(() =>
                           this.$router.push({
                              path: "/"
                           }),
                           4000
                        );
                     } else if (response.data.status == 0 && response.data.remming_minutes == null) {
                        toast.error("عفوا انت لست مشترك في هذا القسم", {
                           timeout: 4000
                        });
                        this.$cookie.removeCookie("landing_content");
                        //   setTimeout(
                        //     () =>
                        //       this.$router.push({
                        //          path: "/",
                        //       }),
                        //    4000
                        //   );
                        //   this.$cookie.setCookie("landing_content",trailer.id);
                        //  const landing = trailer.landing;
                        window.location.href = "https://dsplp.sd.zain.com/?p=" + this.$cookie.getCookie("product_code");
                     } else {
                        toast.info("تم تسجيل دخولك .الرجاء الانتظار حتي يتم تجديد اشتراكك", {
                           timeout: 4000
                        });
                        this.$cookie.setCookie('status', response.data.status, {
                           expire: 60 * response.data.MINTUES,
                        })
                        this.$cookie.setCookie('mdn', this.phone, {
                           expire: 60 * response.data.MINTUES,
                        })
                        this.$cookie.setCookie('minutes', response.data.MINTUES, {
                           expire: 60 * response.data.MINTUES,
                        })
                        setTimeout(
                           () =>
                              this.$router.push({
                                 path: "/",
                              }),
                           4000
                        );
                     }
                  })




            } else {
               // If invalid, show error and clear the formatted number
               toast.info("رقم الهاتف ليس صحيحا . ادخل رقم هاتف بالصيغة الموضحة ");
            }
         } else {
            // If invalid, show error and clear the formatted number
            toast.info("ادخل رقم الهاتف");
         }


         // /////////////////////////////////////////////////////


      }
   }
}
</script>

<style></style>